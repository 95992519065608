
.gallery-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  cursor: pointer;

  .gallery-upload__text {
    font-size: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center
  }
}

.gallery-dialog {
  height: max-content;
  padding-right: 8px;

  img {
    width: 150px;
    height: auto;
    cursor: pointer;
    opacity: 0.8;
    padding: 10px;
    margin-top: 10px;

    &:hover {
      opacity: 1;
    }
  }
}
