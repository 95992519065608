/*.login-grid-main .login-main-container .login-container-grid .logo-item1{*/
/*    margin-left: -40px;*/
/*}*/
.form-signin {
  background: #fff;
  border-radius: 15px;
  -webkit-border-radius: 15px !important;
  box-shadow: var(--box-shadow-low) !important;
  padding: 15px 30px !important;
  width: 100% !important;
  max-width: 420px !important;
  margin: auto !important;

  h2.form-signin-heading {
    margin: 0;
    padding: 12px 8px;
    font-size: 1.5rem !important;
    text-align: center;
    color: var(--color-1);
  }

  .btn-login {
    padding: 8px;
    background: var(--color-6);
    color: #fff;
    margin: 8px 0;
    border-radius: 4px !important;
    border-color: var(--color-6) !important;
    -webkit-border-radius: 4px !important;
  }
}

.login_logo {
  text-align: center;
  border-bottom: 1px solid #ececec;
  padding-bottom: 4px;
}

.login-wrap {
  position: relative;
}

label {
  color: var(--color-1);
}

i.fa.fa-long-arrow-right {
  position: absolute;
  right: 0;
  margin-top: 25px;
  margin-right: 20px;
  color: #fff;
}

.btn-login {
  &:hover {
    background: var(--color-6) !important;
    border-color: var(--color-6) !important;
    outline: none;
  }

  &:focus {
    background: var(--color-6) !important;
    border-color: var(--color-6) !important;
    outline: none;
  }
}

.user-login-info {
  .form-control {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.forgot {
  margin: 0 auto;
}

.login-check {
  float: left;
}

.login-para {
  float: right;
}

.login-bottom {
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1rem;

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    padding: 25px 0px 0px 0px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    margin: 4px 0px 10px 0px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin: 20px 0px 0px 0px;

    a {
      font-size: 16px;
      font-weight: 400;
      color: rgb(219, 0, 0);
    }
  }
}

.reg-bwn {
  margin: 25px 0px 0px 0px;

  a {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #2DC2BC;
    padding: 8px 25px;
    border-radius: 5px;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.button {
  a {
    span {
      margin-top: 8px;
      display: block;
    }
  }
}

.clear {
  clear: both;
}

.circle {
  border: 0.1em solid black;
  border-radius: 100%;
  height: 2em;
  width: 2em;
  text-align: center;
}

.numberCircle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding-bottom: 30px;
  background: unset;
  border: 1px solid #fff;
  color: white;
  text-align: center;
  font-size: 20px;
}

.colorBox {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.plusBox {
  width: 30px;
  height: 30px;
  padding-bottom: 30px;
  background: white;
  border: 1px solid #f5f5f5;
  color: #c4c4c4;
  text-align: center;
  font-size: 20px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .main-card {
    box-shadow: var(--box-shadow-low);
    border: 0;

    .main-grid {
      width: 900px;
      height: 600px;
      background-color: white;

      .second-item {
        margin-left: 50px;
      }
    }
  }
}

.register-input-box {
  min-width: 200px;
}

.page1-grid-main {
  margin-left: 80px;

  .item1 {
    margin-left: 350px;
  }

  .item2 {
    margin-top: 100px;

    .heading-text {
      font-size: 30px;
      font-weight: 300;
    }
  }

  .item3 {
    .sub-heading-text {
      font-weight: 200;
    }
  }

  .item4 {
    .grid-inside1 {
      min-height: 200px;

      .item_1 {
        .next-button {
          background-color: black;
          color: white;
          min-width: 70px;

          &:disabled {
            background-color: #e5e4e4 !important;
          }
        }
      }

      .item_2 {
        .next-button {
          background-color: black;
          color: white;
          min-width: 70px;

          &:disabled {
            background-color: #e5e4e4 !important;
          }
        }
      }
    }
  }
}

.page4-grid-main {
  .item2-title {
    .sub-heading-text {
      font-weight: 200;
    }

    .heading-text {
      font-weight: 300;
      font-size: 30px;
    }

    margin-bottom: 5px;
  }

  .button-item1 {
    .next-button {
      background-color: black;
      color: white;
      min-width: 70px;

      &:disabled {
        background-color: #e5e4e4 !important;
      }
    }
  }

  .item1-logo {
    margin-left: 350px;
  }

  .item3-form1 {
    .text-field {
      width: 230px;
      box-shadow: var(--box-shadow-low);
      border: 0;
    }
  }

  .text-list-grid {
    .card-item {
      margin-top: 30px;
      margin-left: 30px;
    }

    .text-items {
      .sub-grid {
        .heading {
          font-size: 20px;
          font-weight: 200;
        }

        .list-grid-container {
          .icon {
            font-size: 40px;
          }

          .text-field {
            width: 200px;
            box-shadow: var(--box-shadow-low);
            border: 0;
          }
        }
      }
    }
  }
}

.page5-grid-main {
  .item-title {
    .sub-title {
      font-weight: 200;
    }

    .heading-text {
      font-weight: 300;
      font-size: 30px;
    }

    margin-bottom: 40px;
  }

  .text-field-grid {
    .field-heading {
      font-weight: 200;
    }

    .text-field {
      box-shadow: var(--box-shadow-low);
      border: 0;
    }

    .text-field-discount {
      box-shadow: var(--box-shadow-low);
      border: 0;
      width: 100px;
    }
  }

  .button-item {
    .next-button {
      background-color: black;
      color: white;
      min-width: 70px;
    }

    margin-top: 50px;
  }

  .item-logo {
    margin-left: 350px;
  }
}

.page1-side-image {
  height: 600px;
  display: block;
  max-width: 350px;
  overflow: hidden;
}

.text-box {
  box-shadow: var(--box-shadow-low);
  border: 0;
}

.page2-grid-main {
  .sub-grid {
    .item_5 {
      .next-button {
        background-color: black;
        color: white;
        min-width: 70px;

        &:disabled {
          background-color: #e5e4e4 !important;
        }
      }


      margin-top: 70px;
      margin-left: 440px;
    }
  }

  .item1 {
    margin-left: 350px;
  }

  .item2 {
    margin-top: 20px;
    margin-bottom: 20px;

    .heading-text {
      font-weight: 300;
      font-size: 30px;
    }
  }
}

.page3-grid-main {
  .page3-sub-grid3 {
    .next-button {
      background-color: black;
      color: white;
      min-width: 70px;

      &:disabled {
        background-color: #e5e4e4 !important;
      }
    }

    margin-top: 47px;
  }

  .item2 {
    .heading-text {
      font-weight: 300;
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  .item_image {
    margin-left: 350px;
  }

  item2 {
    margin-bottom: 10px;
  }

  .page3-sub-grid1 {
    .page3-sub-grid1-item1 {
      .text-field {
        width: 150px;
        background-color: transparent;
        box-shadow: none;
      }

      .edit-icon {
        font-size: 30px;
        padding-bottom: 10px;
      }
    }

    .page3-sub-grid1-item2 {
      .upload-button-card {
        padding: 20px;
        width: 200px;
        margin-left: 60px;

        .upload-button {
          background-color: #ac5bfd;
          color: white;
        }
      }
    }
  }

  .page3-sub-grid2 {
    .item2 {
      margin-left: 90px;
    }

    .item1 {
      .inside-grid {
        .text-weight {
          font-weight: 200;
        }
      }
    }
  }
}

.next-button {
  background-color: black;
  color: white;
  min-width: 70px;

  &:disabled {
    background-color: #e5e4e4 !important;
  }
}

.page2-sidebar-grid {
  height: 600px;

  .item1 {
    padding: 40px;

    .page2-sidebar-font {
      font-size: 18px;
      color: white;
      font-weight: 200;
    }
  }

  .item2 {
    margin-top: 20px;
  }
}

.heading-text {
  font-weight: 300;
  font-size: 30px;
}

.account-text {
  box-shadow: var(--box-shadow-low);
  width: 300px;
  border: 0;
}

.page-3-4-side-bar {
  height: 600px;

  .sidebar-sub-grid {
    margin-top: 50px;
    margin-inline: 20px;

    .list-item {
      margin-top: 20px;

      .list-item-text {
        color: white;
      }

      .active-item {
        font-weight: 600;
        color: white;
      }
    }
  }
}

.color-selection {
  .color-selection-card {
    .sub-grid {
      .color-item2 {
        .color-name {
          font-weight: 200;
        }
      }

      width: 100px;
    }

    width: 100px;
  }

  .side-text {
    font-weight: 200;
  }
}

.page5_side_image {
  height: 200px;
}

.details-card-plan {
  border-color: #f5f5f5;
  padding: 10px;
  margin: 10px;
  border-width: 10px;
  border-style: solid;

  .color-item {
    width: 100%;

    .color-box-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 10px;
      background-color: #e8d6fc;

      p {
        margin: 0;
      }
    }
  }

  .your-name {
    color: #af7cf5;
    font-size: 20px;
    font-weight: 200;
  }

  .list-container {
    margin-left: 30px;
  }

  .shopping-button-item {
    margin-top: 10px;

    .shopping-button {

      font-size: 12px;
    }

    .shopping-button-selected {
      background-color: #7116ed;
      color: white;
    }
  }
}

.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}

.main-app-card {
  box-shadow: var(--box-shadow-large);
  border: 0;
  border-radius: 10px;
  margin: 10px;

  .login-form {
    .login-grid-main {
      height: 600px;
      width: 900px;
      background-color: white;
      display: flex;
      border-radius: 15px;

      .login-sidebar {
        flex: 1;

        border-radius: 15px;

        .login-sidebar-grid {
          .item1 {
            .login-sidebar-font {
              font-weight: 300;
              padding: 50px;
              color: white;
              font-size: 20px;
              margin-top: 20px;
            }
          }

          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          height: 600px;
          position: relative;
        }
      }

      .login-main-container {
        flex: 2;

        .login-container-grid {
          .heading-item {
            margin-top: 30px;

            .heading {
              font-weight: 300;
              font-size: 30px;
            }
          }

          .logo-item {
            width: 200px;
            padding: 8px;
          }

          .text-control {
            border: 0;
            box-shadow: var(--box-shadow-low);
            width: 300px;
          }

          .text-box-label {
            color: black;
            font-weight: 300;
          }

          .forgot-item {
            margin-left: 200px;
          }

          .forgot-text {
            color: #7116ed;
          }

          .login-button-item {
            margin-top: 20px;

            .button-login {
              min-width: 200px;
              background-color: #7116ed;

              .progress-bar {
                color: white;
                background-color: #7116ed;
              }
            }
          }

          .error-item {
            margin-top: 10px;
            margin-bottom: 10px;
          }

          .register-item {
            margin-top: 10px;

            .text1 {
              font-size: 20px;
            }

            .text2 {
              color: #7116ed;
              font-size: 20px;
            }
          }
        }

        padding-left: 30px;
      }

      @media (max-width: 768px) {
        height: unset;
        width: unset;
      }
    }
  }
}
.paymentLoading{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000000a1;
    z-index: 99999;
}
.paymentLoading::after{
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: .25rem solid #6500ff;
  border-top-color: #00000000;
  animation: paymentLoading .75s linear infinite;
  left: 50%;
  position: absolute;
  top: 50%;
}
@keyframes paymentLoading {
  to {
    transform: rotate(360deg);
  }
}
