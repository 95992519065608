

.revenue-breakdown-dashboard {
  height: 100%;

  .revenue-breakdown-dashboard__card-body {

  }

  .revenue-breakdown-dashboard-wrapper {

    .revenue-breakdown-dashboard-wrapper__item {
      .table-responsive {
        padding: 8px;
      }

      table, thead, tbody, th, td {
        border-style: hidden;
      }

      table tbody tr {

        &:hover {
          box-shadow: var(--box-shadow-low);

          cursor: pointer;
        }
      }
    }
  }

}

.email-sent-dashboard {
  height: 100%;

  .email-sent-dashboard__card-body {

  }


}

.sales-breakdown-dashboard {
  height: 450px;

  .sales-breakdown-dashboard__card-body {

  }

  .sales-breakdown-dashboard-wrapper {

    .sales-breakdown-dashboard-wrapper__item {
      .table-responsive {
        padding: 8px;
      }

      table, thead, tbody, th, td {
        border-style: hidden;
      }

      table tbody tr {

        &:hover {
          box-shadow: var(--box-shadow-low);

          cursor: pointer;
        }
      }
    }
  }

}

.reviews-dashboard {
  .review_wrapper__tile {


    .review-item-wrapper {

      .review-item-wrapper__sub {
        margin: 10px;
        padding: 10px;

        .review-item {
          h6 {
            color: var(--color-9);
            font-weight: 450;
            margin: 0;
          }

          .review-item__date {
            font-size: 0.8rem;
            float: right;
            color: var(--color-10);
          }

          .review-item__message {
            font-size: 0.9rem;
            color: var(--color-11);
            white-space: nowrap;
            overflow: hidden;
            display: block;
            font-weight: 400;
            text-overflow: ellipsis;
            margin-bottom: 0;
          }
        }

        &:hover {
          box-shadow: var(--box-shadow-low);
          cursor: pointer;
        }
      }


    }
  }
}

.chat-in-dashboard_wrapper {
  .chat-in-dashboard {
    height: 360px;

    .chat-in-dashboard__card-body {
      max-height: 425px;
      display: flex;
      flex-direction: column;


      .chat-in-dashboard__card-body__chat_list {
        flex: 1;
        .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
          left: 500px !important;
        }
      }

      .chat-in-dashboard__card-body__see_more {
        flex: 2;
        margin-top: 10px;

        .chat-in-dashboard__card-body__see_more__link {
          float: right;
        }
      }
    }

    .chat-in-dashboard-wrapper {
      .chat-in-dashboard-wrapper__sub {
        margin: 10px;
        padding: 10px;

        .chat-in-dashboard-wrapper__item {
          display: flex;


          .chat-in-dashboard-wrapper__item__img_col_1 {
            .chat-in-dashboard-wrapper__item__image {
              width: 50px;
              height: 50px;
              min-width: 50px;
              border-radius: 50%; /* Also can be 100%, should work the same */
              overflow: hidden;
              display: flex;   /* Ensures the content is properly aligned */
              align-items: center; /* Center align content vertically */
              justify-content: center; /* Center align content horizontally */
          }

          .chat-in-dashboard-wrapper__item__image img {
              width: 100%;
              height: 100%;
              object-fit: cover; /* Cover ensures the image covers the entire area of the container */
          }

          }

          .chat-in-dashboard-wrapper__item__img_col_2 {
            flex: 1;
            padding-left: 10px;

            .chat-in-dashboard-wrapper__item__content {
              .chat-in-dashboard-wrapper__item__content_name {
                h6 {

                  max-lines: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .chat-in-dashboard-wrapper__item__content_message_wrapper {
                .chat-in-dashboard-wrapper__item__content__date {
                  font-size: 0.8rem;
                  text-align: right;
                  margin: auto;
                  display: block;
                  color: var(--color-10);
                }

                .chat-in-dashboard-wrapper__item__content__message {
                  font-size: 0.9rem;
                  display: block;
                  color: var(--color-11);
                  white-space: nowrap;
                  overflow: hidden;
                  font-weight: 400;
                  text-overflow: ellipsis;
                  margin-bottom: 0;
                }
              }


            }
          }


        }

        &:hover {
          box-shadow: var(--box-shadow-low);
          cursor: pointer;
        }
      }
    }
  }
}

.chat-agents {
  .chat-agents-wrapper {
    opacity: 0.4;

    .chat-agents-wrapper__sub {
      padding: 10px;
      margin-bottom: 4px;

      .chat-agents-wrapper__item {
        display: flex;
        align-items: center;

        .chat-agents-wrapper__item__image {
          flex: 0;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }

        .chat-agents-wrapper__item__content {
          padding-left: 10px;
          flex: 1;
          display: flex;
          justify-content: space-between;

          .chat-agents-wrapper__item__content_message_wrapper__name {
            font-size: 0.95rem;
            font-weight: bold;
          }
        }
      }

      &:hover {
        box-shadow: var(--box-shadow-low);
        cursor: pointer;
      }
    }
  }
}

.help-dashboard {
  .help-dashboard-card {
    padding: 20px;
    text-align: center;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;

    .help-dashboard-card-cat-icon {
      height: 80px;
      width: 80px;
      margin: 0 auto 20px auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        border: none;
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .help-dashboard-card-cat-title {
      color: #3f5876;
      font-size: 18px;
      line-height: 1.3;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .help-dashboard-card-cat-text {
      color: #707070;
      font-size: 15px;
    }
  }
}
