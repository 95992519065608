.getting-started {

  .conf0 {
    fill: #FC6394;
  }

  .conf1 {
    fill: #EF3C8A;
  }

  .conf2 {
    fill: #5ADAEA;
  }

  .conf3 {
    fill: #974CBE;
  }

  .conf4 {
    fill: #3CBECD;
  }

  .conf5 {
    fill: #813BBE;
  }

  .conf6 {
    fill: #F9B732;
  }

  .conf7 {
    display: none;
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
  }

  .conf8 {
    fill: none;
    stroke: #F9B732;
    stroke-width: 9;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }


  .confetti-cone {
    transform-origin: 200px 50px;
    animation: confetti-cone1 1.2s ease infinite;
  }

  @keyframes confetti-cone1 {
    0% {
      transform: translate(40px, 95px) rotate(45deg) scale(1, 1);
    }
    15% {
      transform: translate(10px, 145px) rotate(45deg) scale(1.1, 0.85);

    }
    100% {
      transform: translate(40px, 105px) rotate(45deg) scale(1, 1);
    }
  }

  #yellow-strip {
    fill: none;
    stroke: #F9B732;
    stroke-width: 9;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    animation: confdash 1.2s ease infinite;
  }


  @keyframes confdash {
    0% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 500;
      transform: translate(-30px, 30px);
      opacity: 0;
    }
    2% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 500;
      transform: translate(-30px, 30px);
      opacity: 0;
    }
    35% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 900;
      transform: translate(-2px, 0px);
      opacity: 1;
    }

    85% {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      transform: translate(1px, -5px);
      opacity: 1;
    }
    90% {
      stroke-dashoffset: 1000;
      stroke-dashoffset: 1000;
      transform: translate(2px, -8px);
      opacity: 0;
    }
    100% {
      stroke-dashoffset: 1000;
      stroke-dashoffset: 500;
      transform: translate(2px, -8px);
      opacity: 0;
    }
  }


  #a2 {
    transform-origin: 310.9px 241px;
    animation: confa 1.2s ease-out infinite;
  }

  #a1 {
    transform-origin: 276px 246px;
    animation: confa 1.2s ease-out infinite;
  }

  @keyframes confa {
    0% {
      opacity: 0;
      transform: translate(-30px, 20px) rotate(0);
    }
    15% {
      opacity: 1;
      transform: translate(25px, -10px) rotate(60deg);
    }
    80% {
      opacity: 1;
      transform: translate(33px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(37px, -23px) scale(0.5) rotate(230deg);
    }
  }


  #b1 {
    transform-origin: 195.2px 232.6px;
    animation: confb 1.2s ease-out infinite;
  }

  #b2 {
    transform-origin: 230.8px 219.8px;
    animation: confb 1.2s ease-out infinite;
  }

  #b3 {
    transform-origin: 222.8px 190.6px;
    animation: confb 1.2s ease-out infinite;
  }

  #b4 {
    transform-origin: 262px 188.5px;
    animation: confb 1.2s ease-out infinite;
  }

  #b5 {
    transform-origin: 282.3px 170.6px;
    animation: confb 1.2s ease-out infinite;
  }


  @keyframes confb {
    0% {
      opacity: 0;
      transform: translate(-30px, 20px) rotate(0);
    }
    12% {
      opacity: 1;
      transform: translate(25px, -10px) rotate(60deg);
    }
    76% {
      opacity: 1;
      transform: translate(33px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(37px, -23px) scale(0.5) rotate(240deg);
    }
  }

  #c1 {
    transform-origin: 174.8px 183.4px;
    animation: confc 1.2s ease-out infinite;
  }

  #c2 {
    transform-origin: 178.9px 156.2px;
    animation: confc 1.2s ease-out infinite;
  }

  #c3 {
    transform-origin: 206.7px 140px;
    animation: confc 1.2s ease-out infinite;
  }

  #c4 {
    transform-origin: 213.5px 120.2px;
    animation: confc 1.2s ease-out infinite;
  }

  @keyframes confc {
    0% {
      opacity: 0.7;
      transform: translate(-30px, 20px) rotate(0);
    }
    18% {
      opacity: 1;
      transform: translate(5px, -10px) rotate(60deg);
    }
    76% {
      opacity: 1;
      transform: translate(13px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(17px, -23px) scale(0.5) rotate(230deg);
    }
  }

  #d1 {
    transform-origin: 127px 176px;
    animation: confd 1.2s ease-out infinite;
  }

  #d2 {
    transform-origin: 133px 118px;
    animation: confd 1.2s ease-out infinite;
  }

  #d3 {
    transform-origin: 152px 100px;
    animation: confd 1.2s ease-out infinite;
  }

  @keyframes confd {
    0% {
      opacity: 0.7;
      transform: translate(-20px, 20px) rotate(0);
    }
    18% {
      opacity: 1;
      transform: translate(-5px, -10px) rotate(60deg);
    }
    76% {
      opacity: 1;
      transform: translate(-8px, -18px) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: translate(-10px, -23px) scale(0.5) rotate(230deg);
    }
  }

  .modal-content {

    background: rgb(238, 237, 237);

    .modal-body {
      padding: 0;
    }
  }

  .getting-started-main {
    padding: 8px;
    min-height: 600px;
    display: flex;
    @media (max-width: 568px) {
      flex-direction: column;
    }

    .getting-started-celebrate {
      width: 100px;
      height: auto;
    }

    .getting-started-image {
      flex: 0;
      display: flex;
      justify-content: center;

      .getting-started-wrapper {
        height: 100%;
      }

      .getting-started-image-svg {
        height: 100%;
        width: 200px;
      }
    }

    .getting-started-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        font-size: 30px;
        letter-spacing: 2px;
      }

      p {
        font-size: 16px;
      }

      .getting-started-lets-go {
        font-size: 18px;
        width: 150px;
      }
    }
  }

  .automation-getting-started {
    display: flex;
    padding: 0;

    .automation-getting-started-image {
      flex: 1;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        object-fit: cover;
        height: auto;
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .automation-getting-started-content {
      padding: 16px;
      background: white;
      flex: 2.5;

      .automation-getting-started-content-title {
        color: black;
        font-size: 30px;
      }

      .automation-getting-started-content-question {
        display: flex;
        align-items: center;
        justify-content: center;

        .automation-getting-started-content-question-body {
          font-size: 15px;
        }
      }

      .automation-getting-started-info-text {
        font-size: 15px;
        font-style: italic;
        margin-bottom: 32px;
      }
    }

    .automation-getting-started-tiles {
      .automation-getting-started-tile {
        margin-bottom: 8px;
        padding: 4px;
        //background: rgba(232, 232, 232, 0.92);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        input {
          background: #fbfbfd;
        }

        h6 {
          color: black;
          font-weight: 400;
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}

