.email-segmentation {

  .email-segmentation--card{

    overflow: unset;
  }
  .email-segmentation__heading {
    margin-bottom: 10px;

    .delete-button {
      max-width: 10px;
      max-height: 30px;
    }

    .email-segmentation__heading_condition_type {
      margin-top: 4px;
    }
  }

  .email-segmentation__component > * {
    flex: 1 1 auto;
  }

  .email-segmentation__component {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .email-segmentation__component__has {
      flex-grow: 0;
      display: flex;
      -webkit-box-flex: 0;
      align-items: flex-start;

      .email-segmentation__component__has_text {
        flex: 0 0 auto;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        justify-content: normal;
        height: 32px;

        span {
          display: block;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          color: rgb(48, 59, 67);
          white-space: initial;
        }
      }
    }
  }

  .email-segmentation__component > :not(:last-child) {
    margin-right: 8px;
  }

  .email-segmentation__select_input {
    flex-basis: 225px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    display: flex;
    align-items: flex-start;
  }

  .email-segmentation__number_input {
    flex-basis: 100px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    display: flex;
    align-items: flex-start;
  }

  .email-segmentation__select_input > div {
    width: 100%;
  }

  .email-segmentation__date_input {
    flex-basis: 100px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    display: flex;
    align-items: flex-start;
  }

  .email-segmentation__date_input > div {
    width: 100%;
  }

  .email-segmentation__date_input input {
    width: 200px !important;
  }

  .react-datepicker-wrapper:focus {
    border-color: #b2f6ff;
  }
}
